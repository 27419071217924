import { useMemo } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroadcastTower, faFileArrowDown, faBan } from "@fortawesome/free-solid-svg-icons"

function ConnStatePanel({connStat, loaded})
{
    return useMemo(()=>{
        const Title = connStat===null?"Connecting Server":(
            connStat===false?"Loss Connection":(
                !loaded?"Loading Data":""))

        const Icon = !connStat?faBroadcastTower:(!loaded?faFileArrowDown:faBan)

        return (
            <Modal show={!connStat || !loaded}>
                <ModalHeader>
                    <ModalTitle>{Title}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div style={{fontSize: 128, textAlign: "center"}}>
                        <FontAwesomeIcon icon={Icon} /><br/>
                        <span style={{display: connStat===false?"block":"none", fontSize: 16}}>We will try reconnecting in every 5 senconds.</span>
                    </div>
                </ModalBody>
            </Modal>
        )
    }, [connStat, loaded])
}

export default ConnStatePanel