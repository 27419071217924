import EChartsReact from "echarts-for-react";
import { useState, useEffect, useMemo, useRef } from "react";
import { TimeString } from "../../functions/DateHandle";

import { InputGroup } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead'

function KlineChart({ws = WebSocket.prototype, language, KlineData, QuoteData, Runners, selectedRunner, chartSymbol, setChartSymbol})
{

    const chartDataRef = useRef({lastTime: 0, data: []})
    const dataZoom = useRef(true)
    const [chartData, setChartData] = useState([])
    const [prevClose, setPrevClose] = useState(0)

	const SymbolList = useMemo(()=>{
        if (!Runners) return []
        return Object.keys(Runners)
    }, [Runners])

    useEffect(()=>{
		if (ws && ws.readyState === ws.OPEN && chartSymbol !== ""){
            ws.send(JSON.stringify({e: "trading", subE: "kline", runner: selectedRunner, symbol: chartSymbol}))
        }

        setChartData([])
        chartDataRef.current = {lastTime: 0, data: []}
        dataZoom.current = true
	}, [ws, selectedRunner, chartSymbol])

    const CurrQuoteData = QuoteData

    useEffect(()=>{
        if (KlineData && KlineData.runner === selectedRunner && KlineData.symbol === chartSymbol){
            KlineData.klines.forEach((x)=>{
                x.time = new Date(x.time).getTime()
            })
            chartDataRef.current.data = KlineData.klines
            setChartData([...chartDataRef.current.data])
            setPrevClose(KlineData.prevClose)
        }
    }, [selectedRunner, chartSymbol, KlineData])

    useEffect(()=>{
        if (CurrQuoteData)
        {
            const data = chartDataRef.current.data
            const lastItem = data[data.length - 1]

            const price = CurrQuoteData.tradeP!==undefined?CurrQuoteData.tradeP:CurrQuoteData.bid
            const volume = CurrQuoteData.tradeS!==undefined?CurrQuoteData.tradeS:0

            if (price === 0) return

            const currTime = new Date()
            const currTimeBase = currTime - currTime % (60 * 1000)

            if (!lastItem || lastItem.time < currTimeBase)
            {
                data.push({time: currTimeBase, open: price, high: price, low: price, close: price, volume: volume})
            }
            else
            {
                lastItem.close = price
                lastItem.low = Math.min(lastItem.low, price)
                lastItem.high = Math.max(lastItem.high, price)
                lastItem.volume += volume
            }

            if ((currTime - chartDataRef.current.lastTime) > 500)
            {
                chartDataRef.current.lastTime = currTime
                setChartData([...data])
            }
        }
    }, [CurrQuoteData])

    const [option, setOption] = useState({})

    useEffect(()=>{
        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            grid: [
                {
                    top: "5%",
                    left: "7%",
                    right: "3%",
                    bottom: "5%"
                }
            ],
            xAxis: [
                {
                    data: chartData.map((x)=>{
                        return TimeString("hh:mm", new Date(x.time))
                    })
                }
            ],
            yAxis: [
                {
                    scale: true
                }
            ],
            series: [
                {
                    type: 'candlestick',
                    data: chartData.map((x)=>{
                        return [x.open, x.close, x.low, x.high]
                    })
                }
            ]
        }

        if (dataZoom.current && chartData.length > 0){
            dataZoom.current = false
			option.dataZoom = [{
				type: 'inside',
				xAxisIndex: [0],
				start: parseInt((1 - (80/chartData.length))*100),
				end: 100
			}]
        }

        setOption(option)
    }, [chartData])

    const SymbolControl = useMemo(()=>{
        return (
            <InputGroup style={{flex: 1}}>
                <InputGroup.Text>{language.symbol}</InputGroup.Text>
                <Typeahead style={{flex: 1}} id="klineChartSelectSymbol" defaultInputValue="" options={SymbolList}
                    onChange={(e)=>{setChartSymbol(e[0])}}
                    onKeyDown={(e)=>{
                        if (e.key === "Enter"){
                            const value = e.target.value.trim()
                            if (SymbolList.indexOf(value) > -1)
                                setChartSymbol(value)
                            else
                                e.target.value = chartSymbol
                        }
                    }} multiple={false}
                />
            </InputGroup>
        )
    }, [language, chartSymbol, setChartSymbol, SymbolList])

    return useMemo(()=>{
        return (
            <div style={{flex: 3, display: "flex", flexDirection: "column", height: 380}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    {SymbolControl}
                    <div style={{flex: 1, display: "flex", justifyContent: "center", padding: "6px 12px"}}>
                        <div style={{flex: 1, textAlign: "center"}}>
                            {language.prevClose}: {prevClose}
                        </div>
                        <div style={{flex: 1, textAlign: "center"}}>
                            {language.todayOpen}: {(chartData[0]??{open:0}).open}
                        </div>
                    </div>
                </div>
                <div style={{flex: 1}}>
                    <EChartsReact style={{height: "100%"}} option={option} lazyUpdate/>
                </div>
            </div>
        )
    }, [language, SymbolControl, chartData, prevClose, option])
}

export default KlineChart