const RegexHandle = {
    Interger: /^[\d]+$/,
    ReplaceInterger: (value = "")=>{
        return value.replace(/[^\d]/g, "")
    },
    Float: /([0-9]*[.])?[0-9]+/,
    ReplaceFloat: (value = "", lastValue)=>{
        let replaceNotFloat = value.replace(/[^\d.]/g, "")
        const splitByFloat = replaceNotFloat.split(".")
        
        if (splitByFloat.length > 2) return lastValue
        if (splitByFloat.length === 1) return splitByFloat[0]
        return splitByFloat[0] + "." + splitByFloat[1]
    },
    ReplaceMinusableFloat: (value = "", lastValue)=>{
        let replaceNotFloat = value.replace(/[^\d.-]/g, "").replace("--","-")
        const splitByFloat = replaceNotFloat.split(".")
        
        let newValue = null
        if (splitByFloat.length > 2) return lastValue
        if (splitByFloat.length === 1)
            newValue = splitByFloat[0]
        else
            newValue = splitByFloat[0] + "." + splitByFloat[1]

        const splitByMinus = newValue.split("-")
        if (splitByMinus.length > 2) return lastValue
        if (newValue.startsWith("-")) return "-"+splitByMinus[1]
        return splitByMinus[0]
    }
}

export default RegexHandle