const Languages = {
    "en": {
        LanguageSelect:{
            title: "Select A Language",
            language: "Language",
            save: "Save"
        },
        LoginPage: {

        },
        MainPage: {
            runner: "Runner",
            strategy: "Strategy",
            filter: "Filter",
            signaled: "Signaled",
            position: "Position",
            sortBy: "Sort By",
            cost: "Cost",
            BTReturnRate: "History Return Rate",
            BTReturn30Days: "30 Days Return Rate",
            TopNav: {
                logout: "Logout",
                language: "Language"
            },
            KlineChart: {
                symbol: "Symbol",
                selectSymbol: "Select A Symbol",
                prevClose: "Prev.Close",
                todayOpen: "Today.Open"
            },
            RunnerTable: {
                symbol: "Symbol",
                capital: "Capital",
                tradeSize: "TradeSize",
                bidOffset: "Bid Offset",
                signal: "Signal",
                cost: "Cost",
                pos: "Pos",
                floating: "Floating",
                pnl: "PnL",
                bid: "Bid",
                bidS: "Size",
                ask: "Ask",
                askS: "Size",
                forceSell: "Force Sell",
                BTReturnRate: "History Return(%)",
                BTReturn30Days: "30 Days Return(%)",
                otherStatus: "Other Status",
                update: "Update",
                stop: "Stop",
                buy: "BUY",
                sell: "SELL",
                exDividend: "Ex-Dividend",
                pageDisplay: "Page {currPage} of {maxPage}"
            },
            Message: {
                LoadSettingsDone: "Load Settings Done.",
                History: "History: ",
                OrderDoneResp: "Order Filled {symbol} {side} Pirce: {price} Volume: {volume}",
                OrderPartialResp: "Order Partial {symbol} {side} Price: {price} Volume: {volume}",
                OrderRejectResp: "Order Reject {symbol} {side}",
                SetSizeResp: "{runner} {symbol} {strategy} Set Size to {size}",
                SetCapitalResp: "{runner} {symbol} {strategy} Set Capital to {capital}"
            }
        }
    },
    "zh-hant":{
        LanguageSelect:{
            title: "選擇語言",
            language: "語言",
            save: "保存"
        },
        MainPage: {
            runner: "執行體",
            strategy: "策略",
            filter: "過濾",
            signaled: "有訊號",
            position: "持倉",
            sortBy: "排序",
            cost: "成本",
            BTReturnRate: "歷史回報率",
            BTReturn30Days: "過去30天回報率",
            TopNav: {
                logout: "登出",
                language: "語言"
            },
            KlineChart: {
                symbol: "代號",
                selectSymbol: "選擇一個代號",
                prevClose: "昨收",
                todayOpen: "今開"
            },
            RunnerTable: {
                symbol: "代號",
                capital: "投放資金",
                tradeSize: "訂單大小",
                bidOffset: "買價偏移",
                signal: "訊號",
                cost: "成本",
                pos: "持倉",
                floating: "浮動盈利",
                pnl: "盈利",
                bid: "買價",
                bidS: "數量",
                ask: "賣價",
                askS: "數量",
                forceSell: "強制賣出",
                BTReturnRate: "歷史回報(%)",
                BTReturn30Days: "過去30天回報(%)",
                otherStatus: "其他狀態",
                update: "更新",
                stop: "停止",
                buy: "買",
                sell: "賣",
                exDividend: "除凈",
                pageDisplay: "第{currPage}頁 / 共{maxPage}頁"
            },
            Message: {
                LoadSettingsDone: "設定載入完成。",
                History: "歷史: ",
                OrderDoneResp: "訂單完成 {symbol} {side} 價格:{price} 數量:{volume}",
                OrderPartialResp: "訂單部分成交 {symbol} {side} 價格:{price} 數量:{volume}",
                OrderRejectResp: "下單失敗 {symbol} {side}",
                SetSizeResp: "{runner} {symbol} {strategy} 訂單大小設定為 {size}",
                SetCapitalResp: "{runner} {symbol} {strategy} 投放資金設定為 {capital}"
            }
        }
    },
    "zh-hans":{
        LanguageSelect:{
            title: "选择语言",
            language: "语言",
            save: "保存"
        },
        MainPage: {
            runner: "执行体",
            strategy: "策略",
            filter: "过滤",
            signaled: "有信号",
            position: "持仓",
            sortBy: "排序",
            cost: "成本",
            BTReturnRate: "历史回报率",
            BTReturn30Days: "过去30天回报率",
            TopNav: {
                logout: "登出",
                language: "语言"
            },
            KlineChart: {
                symbol: "代号",
                selectSymbol: "选择一个代号",
                prevClose: "昨收",
                todayOpen: "今开"
            },
            RunnerTable: {
                symbol: "代号",
                capital: "投放资金",
                tradeSize: "订单大小",
                bidOffset: "买价偏移",
                signal: "信号",
                cost: "成本",
                pos: "持仓",
                floating: "浮动盈利",
                pnl: "盈利",
                bid: "买价",
                bidS: "数量",
                ask: "卖价",
                askS: "数量",
                forceSell: "强制卖出",
                BTReturnRate: "历史回报(%)",
                BTReturn30Days: "过去30天回报(%)",
                otherStatus: "其他状态",
                update: "更新",
                stop: "停止",
                buy: "买",
                sell: "卖",
                exDividend: "除净",
                pageDisplay: "第{currPage}页 / 共{maxPage}页"
            },
            Message: {
                LoadSettingsDone: "设定加载完毕。",
                History: "歷史: ",
                OrderDoneResp: "订单完成 {symbol} {side} 价格:{price} 数量:{volume}",
                OrderPartialResp: "订单部分成交 {symbol} {side} 价格:{price} 数量:{volume}",
                OrderRejectResp: "下单失败 {symbol} {side}",
                SetSizeResp: "{runner} {symbol} {strategy} 订单大小设定为 {size}",
                SetCapitalResp: "{runner} {symbol} {strategy} 投放资金设定为 {capital}"
            }
        }
    }
}

const LanguageNiceName = {
    "en": "English",
    "zh-hant": "繁體中文",
    "zh-hans": "简体中文"
}

function LoadLanguage()
{
    const language = localStorage.getItem("language")??window.navigator.language

    if (language === "zh-hant" || language === "zh-TW" || language === "zh-HK")
        return Object.assign({}, Languages["en"], Languages["zh-hant"] )
    if (language === "zh-hans" || language === "zh-CN" || language === "zh")
        return Object.assign({}, Languages["en"], Languages["zh-hans"])
    return Languages["en"]
}

function CurrentLanguage()
{
    const language = localStorage.getItem("language")??window.navigator.language

    if (language === "zh-hant" || language === "zh-TW" || language === "zh-HK")
        return "zh-hant"
    if (language === "zh-hans" || language === "zh-CN" || language === "zh")
        return "zh-hans"
    return "en"
}

export { LoadLanguage, CurrentLanguage, LanguageNiceName }