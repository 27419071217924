const Status = {
    Success:    0,
    Error:      1
}

const Side = {
    None: 0,
    Bid: 1,
    Ask: 2
}

const OrderStatus = {
    Fill: 1,
    Partial: 2,
    Reject: 3
}

const ModifyType = {
    Capital: 1,
    TradeSize: 2,
    BidOffset: 3,
    AskOffset: 4
}

const Enums = {
    Status,
    Side,
    OrderStatus,
    ModifyType
}

export default Enums