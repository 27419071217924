import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/color.css'

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Router>
		<Routes>
			<Route path="main" element={<MainPage/>}/>
			<Route path="login" element={<LoginPage/>}/>
			<Route path="*" element={<Navigate to="/login" />}/>
		</Routes>
	</Router>
);