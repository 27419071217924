import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

import { Button, FormControl, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";

import Enums from "../../functions/Enums";
import RegexHandle from "../../functions/RegexHandle";
import { ParseBigNumber } from "../../functions/NumberProcess";
import Decimal from "decimal.js";

const MainContext = createContext()

function RunnerRow({ws=WebSocket.prototype, language, symbol, selectedStrategy})
{
	const Context = useContext(MainContext)
	const QuoteData = useMemo(()=>{
		return Context.QuoteData[symbol]
	}, [Context.QuoteData, symbol])
	const {bid, bidS, ask, askS} = useMemo(()=>{
		if (!QuoteData) return {bid: 0, ask: 0}
		return QuoteData
	}, [QuoteData])

	const [InputCapital, setInputCapital] = useState(null)
	const [InputSize, setInputSize] = useState(null)
	const [InputBidOffset, setInputBidOffset] = useState(null)
	const [InputAskOffset, setInputAskOffset] = useState(null)
	const [capital, setCapital] = useState(NaN)
	const [size, setSize] = useState(NaN)
	const [bidOffset, setBidOffset] = useState(NaN)
	const [askOffset, setAskOffset] = useState(NaN)

	const StrategyInstance = useMemo(()=>{
		return Context.Runners[symbol][selectedStrategy]
	}, [Context.Runners, symbol, selectedStrategy])

	useEffect(()=>{
		if (StrategyInstance){
			if (InputSize){
				InputSize.value = StrategyInstance.TradeSize
				setSize(NaN)
			}
			if (InputCapital){
				InputCapital.value = StrategyInstance.Capital
				setCapital(NaN)
			}
			if (InputBidOffset){
				InputBidOffset.value = StrategyInstance.BidOffset
				setBidOffset(NaN)
			}
			if (InputAskOffset){
				InputAskOffset.value = StrategyInstance.AskOffset
				setAskOffset(NaN)
			}
		}
	}, [StrategyInstance, InputSize, InputCapital, InputBidOffset, InputAskOffset])

	const CapitalControl = useMemo(()=>{
		const BtnEnable = (!isNaN(capital) && StrategyInstance.Capital !== capital) || (!StrategyInstance.AllowEnable&&StrategyInstance.Capital)

		return (
			<td key="CapitalControl">
				<div style={{display: "flex", gap: 4, maxWidth: 150}}>
					<div className="d-grid" style={{flex: 1}}>
						<FormControl style={{minWidth: "100%", padding: 4, paddingTop: 0, paddingBottom: 0, textAlign: "right"}} ref={setInputCapital} type="text"
							disabled={!StrategyInstance.AllowEnable}
							onChange={(e)=>{
								e.currentTarget.value = RegexHandle.ReplaceFloat(e.target.value)
								const value = parseFloat(e.currentTarget.value)
								setCapital(value)
							}}
						/>
					</div>
					<div className="d-grid" style={{flex: 1}}>
						<Button variant={BtnEnable?"primary":"secondary"} style={{padding: 0, fontSize: 14}} disabled={!BtnEnable}
							onClick={()=>{
								if (ws && ws.readyState === ws.OPEN){
									if (StrategyInstance.AllowEnable)
										ws.send(JSON.stringify({e: "trading", subE: "modifyRow", rowID: StrategyInstance.RowID, modifyType: Enums.ModifyType.Capital, decimalValue: capital}))
									else
										ws.send(JSON.stringify({e: "trading", subE: "modifyRow", rowID: StrategyInstance.RowID, modifyType: Enums.ModifyType.Capital, decimalValue: 0}))
								}
							}}
						>
							{StrategyInstance.AllowEnable?language.update:language.stop}
						</Button>
					</div>
				</div>
			</td>
		)
	}, [ws, language, capital, StrategyInstance])

	const SizeControl = useMemo(()=>{
		const BtnEnable = (!isNaN(size) && StrategyInstance.TradeSize !== size) || (!StrategyInstance.AllowEnable&&StrategyInstance.TradeSize)

		return (
			<td key="SizeControl">
				<div style={{display: "flex", gap: 4, maxWidth: 150}}>
					<div className="d-grid" style={{flex: 1}}>
						<FormControl style={{minWidth: "100%", padding: 4, paddingTop: 0, paddingBottom: 0, textAlign: "right"}} ref={setInputSize} type="text"
							disabled={!StrategyInstance.AllowEnable}
							onChange={(e)=>{
								e.currentTarget.value = RegexHandle.ReplaceFloat(e.target.value)
								const value = parseFloat(e.currentTarget.value)
								setSize(value)
							}}
						/>
					</div>
					<div className="d-grid" style={{flex: 1}}>
						<Button variant={BtnEnable?"primary":"secondary"} style={{padding: 0, fontSize: 14}} disabled={!BtnEnable}
							onClick={()=>{
								if (ws && ws.readyState === ws.OPEN){
									if (StrategyInstance.AllowEnable)
										ws.send(JSON.stringify({e: "trading", subE: "modifyRow", rowID: StrategyInstance.RowID, modifyType: Enums.ModifyType.TradeSize, decimalValue: size}))
									else
										ws.send(JSON.stringify({e: "trading", subE: "modifyRow", rowID: StrategyInstance.RowID, modifyType: Enums.ModifyType.TradeSize, decimalValue: 0}))
								}
							}}
						>
							{StrategyInstance.AllowEnable?language.update:language.stop}
						</Button>
					</div>
				</div>
			</td>
		)
	}, [ws, language, size, StrategyInstance])

	const BidOffsetControl = useMemo(()=>{
		const BtnEnable = (!isNaN(bidOffset) && StrategyInstance.BidOffset !== bidOffset)

		return (
			<td key="BidOffsetControl">
				<div style={{display: "flex", gap: 4, maxWidth: 150}}>
					<div className="d-grid" style={{flex: 1}}>
						<FormControl style={{minWidth: "100%", padding: 4, paddingTop: 0, paddingBottom: 0, textAlign: "right"}} ref={setInputBidOffset} type="text"
							onChange={(e)=>{
								e.currentTarget.value = RegexHandle.ReplaceMinusableFloat(e.target.value, bidOffset)
								const value = parseFloat(e.currentTarget.value)
								setBidOffset(value)
							}}
						/>
					</div>
					<div className="d-grid" style={{flex: 1}}>
						<Button variant={BtnEnable?"primary":"secondary"} style={{padding: 0, fontSize: 14}} disabled={!BtnEnable}
							onClick={()=>{
								if (ws && ws.readyState === ws.OPEN){
									ws.send(JSON.stringify({e: "trading", subE: "modifyRow", rowID: StrategyInstance.RowID, modifyType: Enums.ModifyType.BidOffset, decimalValue: bidOffset}))
								}
							}}
						>
							{language.update}
						</Button>
					</div>
				</div>
			</td>
		)
	}, [ws, language, bidOffset, StrategyInstance])

	const AskOffsetControl = useMemo(()=>{
		const BtnEnable = (!isNaN(askOffset) && StrategyInstance.AskOffset !== askOffset)

		return (
			<td key="AskOffsetControl">
				<div style={{display: "flex", gap: 4, maxWidth: 150}}>
					<div className="d-grid" style={{flex: 1}}>
						<FormControl style={{minWidth: "100%", padding: 4, paddingTop: 0, paddingBottom: 0, textAlign: "right"}} ref={setInputBidOffset} type="text"
							onChange={(e)=>{
								e.currentTarget.value = RegexHandle.ReplaceMinusableFloat(e.target.value, askOffset)
								const value = parseFloat(e.currentTarget.value)
								setAskOffset(value)
							}}
						/>
					</div>
					<div className="d-grid" style={{flex: 1}}>
						<Button variant={BtnEnable?"primary":"secondary"} style={{padding: 0, fontSize: 14}} disabled={!BtnEnable}
							onClick={()=>{
								if (ws && ws.readyState === ws.OPEN){
									ws.send(JSON.stringify({e: "trading", subE: "modifyRow", rowID: StrategyInstance.RowID, modifyType: Enums.ModifyType.AskOffset, decimalValue: askOffset}))
								}
							}}
						>
							{language.update}
						</Button>
					</div>
				</div>
			</td>
		)
	}, [ws, language, askOffset, StrategyInstance])

	const [StatusDisplay, HistoryDisplay, ForceSellControl] = useMemo(()=>{
		const SignalClass = StrategyInstance.Signal===Enums.Side.Bid?"bg-success text-white":StrategyInstance.Signal===Enums.Side.Ask?"bg-danger text-white":""
		const SignalStr = StrategyInstance.Signal===Enums.Side.Bid?language.buy:StrategyInstance.Signal===Enums.Side.Ask?language.sell:"-"

		const BackTestPnLClass = StrategyInstance.BTReturnRate>0?"text-success":StrategyInstance.BTReturnRate<0?"text-danger":""
		const Back30DaysPnLClass = StrategyInstance.BTReturn30Days>0?"text-success":StrategyInstance.BTReturn30Days<0?"text-danger":""
		return [
			(
				<React.Fragment key="StatusDisplay">
					<td className={SignalClass + " text-center"}>{SignalStr}</td>
					<td style={{textAlign: "right"}}>{StrategyInstance.Cost}</td>
					<td style={{textAlign: "right"}}>{StrategyInstance.Pos}</td>
				</React.Fragment>
			),
			(
				<React.Fragment key="HistoryDisplay">
					<td className={BackTestPnLClass} style={{textAlign: "right"}}>{!isNaN(StrategyInstance.BTReturnRate)?StrategyInstance.BTReturnRate:"-"}</td>
					<td className={Back30DaysPnLClass} style={{textAlign: "right"}}>{!isNaN(StrategyInstance.BTReturn30Days)?StrategyInstance.BTReturn30Days:"-"}</td>
					<td>
						{
							StrategyInstance.isExDividend?
								<OverlayTrigger key="ExDividend"
									overlay={(props)=><Tooltip {...props}>{language.exDividend}</Tooltip>}
								>
									<FontAwesomeIcon style={{color: "#ffc107"}} icon={faSackDollar}/>
								</OverlayTrigger>
								:null
						}
					</td>
				</React.Fragment>
			),
			(
				<td key="ForceSell">
					<Button variant={StrategyInstance.Pos>0?"danger":"secondary"} style={{width: 100, fontSize: 14, padding: "0px 8px"}}
						disabled={StrategyInstance.Pos===0}
						onClick={()=>{
							if (ws && ws.readyState === ws.OPEN){
								ws.send(JSON.stringify({e: "trading", subE: "force", rowID: StrategyInstance.RowID, side: Enums.Side.Ask}))
							}
						}}
					>
						{language.forceSell}
					</Button>
				</td>
			)
		]
	}, [ws, language, StrategyInstance])

	return useMemo(()=>{
		const Floating = new Decimal(bid).minus(new Decimal(StrategyInstance.Cost)).mul(StrategyInstance.Pos).toNumber()
		const FloatingReturn = Math.round(Floating/StrategyInstance.Cost*1000)/10
		const FloatingClass = Floating>0?"pnl-win":Floating<0?"pnl-loss":StrategyInstance.Pos>0?"pnl-noprofit":""

		const PnL = Math.round(StrategyInstance.PnL*100000)/100000
		const PnLClass = StrategyInstance.PnL>0?"pnl-win":StrategyInstance.PnL<0?"pnl-loss":""

		return (
			<tr>
				<td>{symbol}</td>
				{CapitalControl}
				{SizeControl}
				{BidOffsetControl}
				{StatusDisplay}
				<td className={FloatingClass} style={{textAlign: "right"}}>{bid!==0?Floating:"-"} ({isNaN(FloatingReturn)?"-":FloatingReturn}%)</td>
				<td className={PnLClass} style={{textAlign: "right"}}>{PnL}</td>
				<td className="text-success text-center">{bidS?ParseBigNumber(bidS*bid):"-"}</td>
				<td className="text-success text-center">{bid||"-"}</td>
				<td className="text-danger text-center">{ask||"-"}</td>
				<td className="text-danger text-center">{askS?ParseBigNumber(askS*ask):"-"}</td>
				{ForceSellControl}
				{HistoryDisplay}
			</tr>
		)
	}, [symbol, StrategyInstance, bid, bidS, ask, askS, CapitalControl, SizeControl, BidOffsetControl, StatusDisplay, ForceSellControl, HistoryDisplay])
}

function RunnerTable({ws, language, Runners, QuoteData, selectedStrategy, filterSettings={signaled: false}, sortBy})
{
	const [page, setPage] = useState(0)
	const [searchSymbol, setSearchSymbol] = useState("")

	const AllRows = useMemo(()=>{
		if (!Runners) return []

		return Object.keys(Runners).filter((symbol)=>{
			const StrategyInstance = Runners[symbol][selectedStrategy];
			return StrategyInstance !== undefined
		}).map((symbol)=>{
			return {
				symbol,
				instance: Runners[symbol][selectedStrategy],
				element: <RunnerRow key={symbol} ws={ws} language={language} Runners={Runners} symbol={symbol} selectedStrategy={selectedStrategy}/>
			}
		})
	}, [ws, language, Runners, selectedStrategy])

	const FilteredRows = useMemo(()=>{
		return AllRows.filter(({symbol, instance})=>{
			return (
				symbol.indexOf(searchSymbol) > -1 &&
				(!filterSettings.signaled || instance.Signal !== Enums.Side.None) &&
				(!filterSettings.position || instance.Pos > 0)
			)
		}).sort((a, b)=>{
			const aobj = a.instance[sortBy]
			const bobj = b.instance[sortBy]
			if (sortBy === "BTReturnRate" || sortBy === "BTReturn30Days"){
				if (isNaN(aobj) || isNaN(bobj))
				{
					if (!isNaN(aobj)) return -1
					if (!isNaN(bobj)) return 1
					return 0
				}
				else return bobj - aobj
			}
			if (sortBy === "Cost" || sortBy === "Pos") return bobj - aobj
			return aobj - bobj
		})
	}, [AllRows, filterSettings, sortBy, searchSymbol])

	const Rows = useMemo(()=>{
		if (FilteredRows.length === 0) return null
		
		return FilteredRows.slice(page*50,page*50+50).map(({element})=>{
			return element
		})
	}, [FilteredRows, page])

	useEffect(()=>{
		setPage(0)
	}, [FilteredRows.length, searchSymbol, selectedStrategy, sortBy])

	const [TotalFloating, TotalPnL] = useMemo(()=>{
		let floating = 0
		let pnl = 0

		if (!Runners) return [0, 0]

		Object.keys(Runners).forEach((symbol)=>{
			const StrategyInstance = Runners[symbol][selectedStrategy]
			if (!StrategyInstance) return

			pnl += Math.round(StrategyInstance.PnL*1000)/1000
			floating += Math.round((QuoteData[symbol].bid - StrategyInstance.Cost)*1000)/1000 * StrategyInstance.Pos
		})

		return [Math.round(floating*1000)/1000, Math.round(pnl*1000)/1000]
	}, [Runners, selectedStrategy, QuoteData])

	const TableHeader = useMemo(()=>{
		const FloatingClass = TotalFloating>0?"text-success":TotalFloating<0?"text-danger":""
		const PnLClass = TotalPnL>0?"text-success":TotalPnL<0?"text-danger":""

		return (
			<thead>
				<tr>
					<td style={{width: 100}}>
						<FormControl style={{padding: "0px 8px"}} placeholder={language.symbol} value={searchSymbol}
							onChange={(e)=>{ setSearchSymbol(e.target.value.trim()) }}
						/>
					</td>
					<td style={{width: 150}}>{language.capital}</td>
					<td style={{width: 150}}>{language.tradeSize}</td>
					<td style={{width: 150}}>{language.bidOffset}</td>
					<td className="text-center">{language.signal}</td>
					<td style={{textAlign: "right"}}>{language.cost}</td>
					<td style={{textAlign: "right"}}>{language.pos}</td>
					<td style={{textAlign: "right", width: 150}}><span className={FloatingClass}>({TotalFloating})</span> {language.floating}</td>
					<td style={{textAlign: "right", width: 150}}><span className={PnLClass}>({TotalPnL})</span> {language.pnl}</td>
					<td className="text-success text-center" style={{width: 80}}>{language.bidS}</td>
					<td className="text-success text-center" style={{width: 80}}>{language.bid}</td>
					<td className="text-danger text-center" style={{width: 80}}>{language.ask}</td>
					<td className="text-danger text-center" style={{width: 80}}>{language.askS}</td>
					<td style={{width: 100, textAlign: "center"}}>{language.forceSell}</td>
					<td style={{textAlign: "right"}}>{language.BTReturnRate}</td>
					<td style={{textAlign: "right"}}>{language.BTReturn30Days}</td>
					<td>{language.otherStatus}</td>
				</tr>
			</thead>
		)
	}, [language, searchSymbol, TotalFloating,TotalPnL])

	const PageControl = useMemo(()=>{
		const MaxPage = Math.floor(FilteredRows.length / 50)

		return (
			<div style={{width: "100%", display: "flex"}}>
				<Button disabled={page === 0} style={{width: 120}} onClick={()=>{
					setPage((last)=>last-1)
				}}>
					&lt;
				</Button>
				<div style={{flex: 1, textAlign: "center", padding: "6px"}}>
					{language.pageDisplay.replace("{currPage}", page+1).replace("{maxPage}", MaxPage+1)}
				</div>
				<Button disabled={page === MaxPage} style={{width: 120}} onClick={()=>{
					setPage((last)=>last+1)
				}}>
					&gt;
				</Button>
			</div>
		)
	}, [language, FilteredRows, page])

	return useMemo(()=>{
		return (
			<div>
				<Table bordered hover size="sm">
					{TableHeader}
					<tbody>
						<MainContext.Provider value={{Runners, QuoteData}}>
							{Rows}
						</MainContext.Provider>
					</tbody>
				</Table>

				{ PageControl }
			</div>
		)
	}, [Runners, QuoteData, TableHeader, Rows, PageControl])
}

export default RunnerTable