import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { FormCheck, InputGroup, Row, Col, ToastContainer } from "react-bootstrap";
import Form from "react-bootstrap/Form"
import 'bootstrap/dist/css/bootstrap.min.css';

import UserMappingLoader from "../functions/UserMappingLoader";
import Enums from "../functions/Enums";

import RunnerTable from "./MainPage/RunnerTable";
import ConnStatePanel from "./MainPage/ConnStatePanel";
import KlineChart from "./MainPage/KlineChart";
import TopNav from "./MainPage/TopNav";
import { TimeString } from "../functions/DateHandle";
import { LoadLanguage } from "../functions/LanguageHandle";
import AutoHideToast from "../components/AutoHideToast";
import LanguageSelect from "../components/LanguageSelect";

function MainPage(){
	const [userMapping, setUserMapping] = useState(null)
	const [ws, setWs] = useState(null)
	const retryCount = useRef(0)
	const [connStat, setConnStat] = useState(null)
	const [loaded, setLoaded] = useState(false)
	const language = useMemo(()=>{ return LoadLanguage().MainPage}, [])
	const languageRef = useRef(LoadLanguage().MainPage)

	// Data
	const [Runners, setRunners] = useState({})
	const [QuoteData, setQuoteData] = useState({})
	const QuoteDataRef = useRef({lastTime: 0, data: {}})
	const [RealtimeQuote, setRealTimeQuote] = useState({})
	const [KlineData, setKlineData] = useState(null)
	const [Messages, setMessages] = useState([])
	const [Toasts, setToasts] = useState([])
	const [showLanguageSelect, setShowLanguageSelect] = useState(false)

	const AddMessage = useCallback((message, time=null)=>{
		setMessages((old)=>{
			return [...old, {time: time??new Date(), message}]
		})
		setToasts((old)=>{
			return [...old, <React.Fragment key={old.length+1}><AutoHideToast title="Info" message={message} type="info" delay={2000}/></React.Fragment>]
		})
	}, [])

	// Control
	const [selectedRunner, setSelectedRunner] = useState("")
	const Strategies = useMemo(()=>{
		if (Object.keys(Runners).length === 0 || selectedRunner === "") return []
		const strategies = []

		Object.keys(Runners[selectedRunner]).forEach((y)=>{
			Object.keys(Runners[selectedRunner][y]).forEach((z)=>{
				if (strategies.indexOf(z) === -1)
					strategies.push(z)
			})
		})

		return strategies
	}, [Runners, selectedRunner])
	const [selectedStrategy, setSelectedStrategy] = useState("")
	const [chartSymbol, setChartSymbol] = useState("")
	const chartSymbolRef = useRef("")

	useEffect(()=>{
		setChartSymbol("")
		setKlineData(null)
	}, [selectedRunner])

	useEffect(()=>{
		chartSymbolRef.current = chartSymbol
	}, [chartSymbol])
	
	// filter
	const [filterSignaled, setFilterSignaled] = useState(false)
	const [filterPosition, setFilterPosition] = useState(false)
	const filterSettings = useMemo(()=>{
		return {
			signaled: filterSignaled,
			position: filterPosition
		}
	}, [filterSignaled, filterPosition])
	const [sortBy, setSortBy] = useState("Cost")

	useEffect(()=>{
		if (userMapping === null)
		{
			UserMappingLoader.LoadUser(setUserMapping, localStorage.getItem("username"))
			return
		}

		if (userMapping === undefined){
			localStorage.clear()
			window.location.replace("/login")
			return
		}

		if (ws === null)
		{
			const websocket = new WebSocket((window.location.protocol.startsWith("https")?"wss://":"ws://")+(window.location.hostname==="localhost"?"strategy-trader.local":window.location.host)+"/ws/"+userMapping.endpointID)
			websocket.onopen = () => {
				console.log("Connected")
				setConnStat(true)
				websocket.send(JSON.stringify({e: "resume", userID: localStorage.getItem("userID")}))
			}
			websocket.onerror = (err) => {
				retryCount.current = retryCount.current + 1
				if (retryCount.current === 3){
					localStorage.removeItem("userID")
					window.location.replace("/login")
				}
			}
			websocket.onclose = () => {
				console.log("Disconnected")
				setConnStat(false)
				setLoaded(false)
				setTimeout(()=>{
					console.log("Start Reconnect.")
					setWs(null)
				}, 5000)
			}
			websocket.onmessage = (msg) => {
				if (msg.data.length === 0) return
				const json = JSON.parse(msg.data)
				if (json.e === "resume"){
					if (json.status === Enums.Status.Success)
						websocket.send(JSON.stringify({e: "trading", subE: "loadSettings"}))
					else if (json.status === Enums.Status.Error){
						localStorage.removeItem("userID")
						window.location.replace("/login")
					}
				}
				else if (json.e === "trading"){
					if (json.subE === "loadSettings"){
						setLoaded(true)
						setRunners(json.core2sym2strategy2info)
						setQuoteData(()=>{
							const obj = {}
							Object.keys(json.core2sym2strategy2info).forEach((runner)=>{
								QuoteDataRef.current.data[runner] = {}
								obj[runner] = {}
								Object.keys(json.core2sym2strategy2info[runner]).forEach((symbol)=>{
									QuoteDataRef.current.data[runner][symbol] = {bid: 0, ask: 0, bidS:0, askS:0}
									obj[runner][symbol] = {bid: 0, ask: 0, bidS:0, askS:0}
								})
							})
							return obj
						})
						setRealTimeQuote(()=>{
							const obj = {}
							Object.keys(json.core2sym2strategy2info).forEach((runner)=>{
								obj[runner] = {}
								Object.keys(json.core2sym2strategy2info[runner]).forEach((symbol)=>{
									obj[runner][symbol] = {bid: 0, ask: 0, bidS:0, askS:0}
								})
							})
							return obj
						})
						AddMessage(languageRef.current.Message.LoadSettingsDone)
					}
					else if (json.subE === "updateSetting"){
						setRunners((old)=>{
							const newobj = Object.assign({}, old)
							newobj[json.runner] = Object.assign({}, old[json.runner])
							newobj[json.runner][json.symbol] = Object.assign({}, old[json.runner][json.symbol])
							newobj[json.runner][json.symbol][json.strategy] = Object.assign({}, old[json.runner][json.symbol][json.strategy], json.strategyInfo)
							return newobj
						})

						if (json.strategyInfo.TradeSize !== undefined && json.strategyInfo.Capital === 0){
							AddMessage(
								languageRef.current.Message.SetSizeResp.replace(
								"{runner}", json.runner).replace("{symbol}", json.symbol).replace(
								"{strategy}", json.strategy).replace("{size}", json.strategyInfo.TradeSize)
							)
						}
						else if (json.strategyInfo.Capital !== undefined && json.strategyInfo.TradeSize === 0){
							AddMessage(
								languageRef.current.Message.SetCapitalResp.replace(
								"{runner}", json.runner).replace("{symbol}", json.symbol).replace(
								"{strategy}", json.strategy).replace("{capital}", json.strategyInfo.Capital)
							)
						}
					}
					else if (json.subE === "orderResponse" || json.subE === "historyOrder"){
						const order = json.order
						if (order.status === Enums.OrderStatus.Fill){
							AddMessage(
								(json.subE === "historyOrder"?languageRef.current.Message.History:"")+
								languageRef.current.Message.OrderDoneResp.replace(
									"{symbol}", order.symbol).replace(
									"{side}", order.side===Enums.Side.Bid?"BUY":"SELL").replace(
									"{price}", order.price.toString()).replace("{volume}", order.volume.toString())
								, new Date(order.time)
							)
						}
						else if (order.status === Enums.OrderStatus.Partial){
							AddMessage(
								(json.subE === "historyOrder"?languageRef.current.Message.History:"")+
								languageRef.current.Message.OrderPartialResp.replace(
									"{symbol}", order.symbol).replace(
									"{side}", order.side===Enums.Side.Bid?"BUY":"SELL").replace(
									"{price}", order.price.toString()).replace("{volume}", order.volume.toString())
								, new Date(order.time)
							)
						}
						else if (order.status === Enums.OrderStatus.Reject){
							AddMessage(
								(json.subE === "historyOrder"?languageRef.current.Message.History:"")+
								languageRef.current.Message.OrderRejectResp.replace(
									"{symbol}", order.symbol).replace(
									"{side}", order.side===Enums.Side.Bid?"BUY":"SELL")
								, new Date(order.time)
							)
						}
					}
					else if (json.subE === "quote"){
						const haveLast = QuoteDataRef.current.data[json.runner][json.quote.symbol].time!==undefined
						QuoteDataRef.current.data[json.runner] = Object.assign({}, QuoteDataRef.current.data[json.runner])
						QuoteDataRef.current.data[json.runner][json.quote.symbol] = json.quote

						if (json.quote.symbol === chartSymbolRef.current){
							setRealTimeQuote(json.quote)
						}

						const currTime = new Date().getTime()
						if (!haveLast || currTime - QuoteDataRef.current.lastTime > 250){
							setQuoteData(Object.assign({}, QuoteDataRef.current.data))
							QuoteDataRef.current.lastTime = currTime
						}
					}
					else if (json.subE === "kline"){
						if (json.status === Enums.Status.Success)
							setKlineData(json)
					}
				}
			}

			setWs(websocket)
		}
	}, [userMapping, ws, AddMessage])

	const RunnerTaps = useMemo(()=>{
		if (Object.keys(Runners).length > 0 && selectedRunner === ""){
			setSelectedRunner(Object.keys(Runners)[0])
		}

		return (
			<InputGroup style={{fontSize: 16}}>
				<InputGroup.Text style={{minWidth: 100, fontSize: 16, padding: "6px 12px"}}>{language.runner}</InputGroup.Text>
				<Form.Select style={{padding: "6px 12px"}} value={selectedRunner} onChange={(e)=>{ setSelectedRunner(e.target.value) }}>
					{
						Object.keys(Runners).map((x)=>{
							return <option key={x} value={x}>{x}</option>
						})
					}
				</Form.Select>
			</InputGroup>
		)
	}, [language, Runners, selectedRunner])
	const StrategyTaps = useMemo(()=>{
		if (Strategies.length > 0 && (selectedStrategy === "" || Strategies.indexOf(selectedStrategy) === -1)){
			setSelectedStrategy(Strategies[0])
		}

		return (
			<InputGroup>
				<InputGroup.Text style={{minWidth: 100, fontSize: 16, padding: "6px 12px"}}>{language.strategy}</InputGroup.Text>
				<Form.Select value={selectedStrategy} onChange={(e)=>{ setSelectedStrategy(e.target.value) }}>
					{
						Strategies.map((x)=>{
							return <option key={x} value={x}>{x}</option>
						})
					}
				</Form.Select>
			</InputGroup>
		)
	}, [language, Strategies, selectedStrategy])
	const SortBySelect = useMemo(()=>{
		return (
			<InputGroup>
				<InputGroup.Text style={{minWidth: 100, fontSize: 16, padding: "6px 12px"}}>{language.sortBy}</InputGroup.Text>
				<Form.Select value={sortBy} onChange={(e)=>{ setSortBy(e.target.value) }}>
					<option value="Cost">{language.cost}</option>
					<option value="Pos">{language.position}</option>
					<option value="BTReturnRate">{language.BTReturnRate}</option>
					<option value="BTReturn30Days">{language.BTReturn30Days}</option>
				</Form.Select>
			</InputGroup>
		)
	}, [language, sortBy])
	const FilterSelect = useMemo(()=>{
		return (
			<InputGroup>
				<InputGroup.Text style={{minWidth: 100, fontSize: 16, padding: "6px 12px"}}>{language.filter}</InputGroup.Text>
				<InputGroup.Text style={{flex: 1, display: "flex", gap: 16, background: "white"}}>
					<div style={{display: "flex", gap: 8}}>
						<FormCheck value={filterSettings.signaled} onChange={()=>{setFilterSignaled(old=>!old)}}/>
						<span>{language.signaled}</span>
					</div>
					<div style={{display: "flex", gap: 8}}>
						<FormCheck value={filterSettings.position} onChange={()=>{setFilterPosition(old=>!old)}}/>
						<span>{language.position}</span>
					</div>
				</InputGroup.Text>
			</InputGroup>
		)
	}, [language, filterSettings])
	const DisplayMessages = useMemo(()=>{
		Messages.sort(({time: aTime}, {time: bTime})=>{
			return bTime.getTime() - aTime.getTime()
		}).forEach((x, i)=>{
				x.element = (
					<React.Fragment key={i}>
						<span>{TimeString("hh:mm:ss", x.time)} {x.message}</span><br/>
					</React.Fragment>
				)
		})

		return Messages.map((x)=>{return x.element})
	}, [Messages])

	return useMemo(()=>{
		const currRunner = Runners[selectedRunner]
		const currQuote = QuoteData[selectedRunner]

		return (
			<div style={{height: "100vh", display: "flex", flexDirection: "column"}}>
				<TopNav language={language.TopNav} setShowLanguageSelect={setShowLanguageSelect}/>
				<LanguageSelect show={showLanguageSelect} setShow={setShowLanguageSelect}/>
				<div style={{flex: 1, width: "100vw", padding: 8, overflowY: "auto", overflowX: "hidden"}}>
					<ConnStatePanel connStat={connStat} loaded={loaded}/>
					<Row className="mb-2" style={{maxHeight: 380}}>
						<Col md={5} className="mb-3" style={{display: "flex", flexDirection: "column", gap: 4}}>
							{RunnerTaps}
							{StrategyTaps}
							{SortBySelect}
							{FilterSelect}
							<div style={{flex: 1, maxHeight: 196, display: "flex", flexDirection: "row", border: "1px solid #ccc", borderRadius: 5}}>
								<div style={{flex: 1, overflowY: "auto", padding: 4}}>{DisplayMessages}</div>
							</div>
						</Col>
						<Col md={7}>
							<KlineChart ws={ws} language={language.KlineChart} Runners={currRunner} selectedRunner={selectedRunner} chartSymbol={chartSymbol} setChartSymbol={setChartSymbol} QuoteData={RealtimeQuote} KlineData={KlineData}/>
						</Col>
					</Row>
					<RunnerTable ws={ws} language={language.RunnerTable} Runners={currRunner} QuoteData={currQuote} selectedStrategy={selectedStrategy}
						filterSettings={filterSettings} sortBy={sortBy}
					/>
				</div>
				<ToastContainer position="bottom-end" style={{padding: 8}}>
					{Toasts}
				</ToastContainer>
			</div>
		)
	}, [
		ws, language, connStat, loaded, showLanguageSelect,
		RunnerTaps, StrategyTaps, SortBySelect, FilterSelect, DisplayMessages, Toasts,
		filterSettings, sortBy,
		Runners, RealtimeQuote, QuoteData, KlineData,
		selectedRunner, selectedStrategy, chartSymbol
	])
}

export default MainPage