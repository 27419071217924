import { useState, useMemo, useEffect, useCallback } from "react";
import { Card, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form"

import UserMappingLoader from "../functions/UserMappingLoader";
import Enums from "../functions/Enums";

function LoginPage(){
    const [userMapping, setUserMapping] = useState([])
    const [errForm, setErrForm] = useState(null)

    useEffect(()=>{
        if (localStorage.getItem("username") && localStorage.getItem("userID"))
            window.location.assign("/main")
        UserMappingLoader.Loader(setUserMapping)
    }, [])

    const loginHandle = useCallback((e)=>{
        e.preventDefault()
        e.stopPropagation()
        const formData = new FormData(e.target)

        const usermap = userMapping.find((x)=>{ return x.name === formData.get("username") })

        if (usermap === undefined){
            setErrForm("User not Exist.")
            return
        }
        
        let ws = new WebSocket((window.location.protocol.startsWith("https")?"wss://":"ws://")+(window.location.hostname==="localhost"?"strategy-trader.local":window.location.host)+"/ws/"+usermap.endpointID)
        ws.onmessage = (msg)=>{
            if (msg.data.length === 0) return
            const json = JSON.parse(msg.data)
            if (json.e === "login"){
                if (json.status === Enums.Status.Success){
                    localStorage.setItem("username", formData.get("username"))
                    localStorage.setItem("userID", json.userID)
                    window.location.replace("/main")
                }
                else if (json.status === Enums.Status.Error){
                    setErrForm("Wrong Password.")
                }
            }
        }
        ws.onopen = () => { ws.send(JSON.stringify({e: "login", password: formData.get("password")})) }
        ws.onerror = () => { setErrForm("Server Timeout."); }
    }, [userMapping])

    return useMemo(()=>{
        if (userMapping.length===0) return <>Loading...</>
        return (
            <div style={{height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <Card style={{width: 500, maxWidth: 500}}>
                        <Card.Header style={{fontSize: 24}}>Login</Card.Header>
                        <Card.Body>
                            <Form onSubmit={loginHandle}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="text" name="username" required onChange={()=>setErrForm("")}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" required onChange={()=>setErrForm("")}/>
                                </Form.Group>
                                <div className="mb-3" style={{color: "red"}}>{errForm}</div>
                                <Button type="submit">Login</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }, [userMapping, loginHandle, errForm])
}

export default LoginPage