import { useState, useMemo, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { TimeString } from "../../functions/DateHandle";

function TopNav({language, setShowLanguageSelect}){
    const [time, setTime] = useState(new Date())
    const [timer, setTimer] = useState(null)

    useEffect(()=>{
        if (timer === null)
            setTimer(
                setInterval(()=>{
                    setTime(new Date())
                }, 100)
            )
    }, [timer])

    return useMemo(()=>{
        return (
            <Navbar bg="dark" variant="dark" className="text-white">
                <Container fluid style={{display: "flex"}}>
                    <div style={{flex: 1}}>
                        <Navbar.Brand>Strategy Trader</Navbar.Brand>
                    </div>
                    <div style={{flex: 0}}>
                        <span>{TimeString("hh:mm:ss", time)}</span>
                    </div>
                    <div style={{flex: 1, display: "flex", gap: 8, justifyContent: "end", textAlign: "right"}}>
                        <Button size="sm" onClick={(e)=>{
                            e.preventDefault()
                            e.stopPropagation()
                            setShowLanguageSelect(true)
                        }}>
                            {language.language}
                        </Button>
                        <Button size="sm" onClick={()=>{
                            localStorage.removeItem("userID")
                            window.location.assign("/login")
                        }}>
                            {language.logout}
                        </Button>
                    </div>
                </Container>
            </Navbar>
        )
    }, [language, time, setShowLanguageSelect])
}

export default TopNav