function Loader(setUserMapping)
{
    fetch("/settings/UserMapping.json").then(r=>r.json()).then(j=>{
        setUserMapping(j.users)
    })
}

function LoadUser(setUserMapping, username="")
{
    fetch("/settings/UserMapping.json").then(r=>r.json()).then(j=>{
        setUserMapping(j.users.find((x)=>x.name===username))
    })
}

const UserMappingLoader = {
    Loader,
    LoadUser
}

export default UserMappingLoader