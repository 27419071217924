import { useState, useMemo } from "react";

import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { CurrentLanguage, LanguageNiceName, LoadLanguage } from "../functions/LanguageHandle";

function LanguageSelect({show, setShow}){
    const language = useMemo(()=>{ return LoadLanguage().LanguageSelect }, [])

    const [selected, setSelected] = useState(CurrentLanguage())
    const SelectOption = useMemo(()=>{
        return Object.keys(LanguageNiceName).map((key)=>{
            return <option key={key} value={key}>{LanguageNiceName[key]}</option>
        })
    }, [])

    return useMemo(()=>{
        return (
            <Modal show={show} onHide={()=>{setShow(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{language.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={()=>{
                        localStorage.setItem("language", selected)
                        window.location.reload()
                    }}>
                        <Form.Group className="mb-3">
                            <Form.Label>{language.language}</Form.Label>
                            <Form.Select value={selected} onChange={(e)=>{setSelected(e.target.value)}}>
                                {SelectOption}
                            </Form.Select>
                        </Form.Group>
                        <Button type="submit">{language.save}</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }, [show, setShow, language, selected, SelectOption])
}

export default LanguageSelect